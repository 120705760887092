import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { LoadingButton } from "@mui/lab";
import api from 'appConfig/restAPIs';
import axios from 'axios';
import { useUserAuth } from 'shared/contexts/UserAuthContext';

const CreateNote = ({isCreateOpen, setIsCreateOpen, fetchList, customerId}) => {
  const [noteTitle, setNoteTitle] = useState("");
  const [noteContent, setNoteContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useUserAuth();
  const [isError, setIsError] = useState(false);
console.log()
  const handleSaveSubNote = () => {
    if(noteTitle && noteContent){
      setIsLoading(true);

      axios.post(api.subNote, {title: noteTitle, content: noteContent, customerId: customerId, regBy: user.userId})
        .then((resp) => {
          fetchList();
        })
        .finally(() => {
          setIsCreateOpen(false);
          setIsLoading(false);
        })
    }else{
      setIsError(true);
    }
  }

  const clearNote = () => {
    setNoteTitle("");
    setNoteContent("");
  }
  return(
    <Dialog open={isCreateOpen}
      sx={{ "& .MuiPaper-root": {maxWidth: 1000, minWidth: 600, minHeight: 300}}}>
      <DialogTitle>Add a Note</DialogTitle>
      <DialogContent>
        <Grid container direction="column" sx={{ mb: 4 }} rowGap={3}>
          <Grid item>
            <TextField
              label="Title"
              value={noteTitle}
              variant='standard'
              onChange={e => setNoteTitle(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Content"
              value={noteContent}
              fullWidth
              multiline
              rows={3}
              variant='standard'
              onChange={e => setNoteContent(e.target.value)}
            />            
          </Grid>
        </Grid>
        {isError && <Grid container><Typography color="error">Please enter title and Content.</Typography></Grid>}
      </DialogContent>
      <DialogActions>
        <LoadingButton variant="contained" onClick={handleSaveSubNote} disabled={isLoading} loading={isLoading}>
          Save
        </LoadingButton>
        <LoadingButton variant="outlined" onClick={() => clearNote()}>Clear</LoadingButton>
        <LoadingButton variant="outlined" onClick={() => setIsCreateOpen(false)}>Close</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default CreateNote;